import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { ModelsComponent } from './pages/models/models.component';
import { NgxDropzoneModule } from 'ngx-dropzone'; 
import { UploadModelComponent } from './pages/upload-model/upload-model.component';

var firebaseConfig = {
  apiKey: 'AIzaSyBCia_TYS8_YPpBDu-XfGFaEDNZwexl_nM',
  authDomain: 'art-then.firebaseapp.com',
  databaseURL: 'https://art-then.firebaseio.com',
  projectId: 'art-then',
  storageBucket: 'art-then.appspot.com',
  messagingSenderId: '186198646707',
  appId: '1:186198646707:web:92662bd3a7bc81741bb5c6',
  measurementId: 'G-VQDBQY3J0B'
};

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    ToastrModule.forRoot(),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    NgxDropzoneModule
  ],
  declarations: [AppComponent, AdminLayoutComponent, AuthLayoutComponent, ModelsComponent, UploadModelComponent],
  providers: [],
  bootstrap: [AppComponent],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
