import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { Client } from '../models/client.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  db: AngularFirestoreCollection;

  constructor(private firestore: AngularFirestore) {
    this.db = this.firestore
      .collection(environment.firestore)
      .doc(environment.collection)
      .collection(environment.collections.clients);
  }

  create(data: Client) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .doc(data.email)
        .set({ ...data });
    });
  }

  update(key, data) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .doc(key)
        .set(data, { merge: true });
    });
  }

  get(key) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .doc(key)
        .snapshotChanges()
    });
  }

  delete(key) {
    return new Promise<any>((resolve, reject) => {
      this.db
        .doc(key)
        .delete()
    });
  }
}
