import { Component, OnInit } from '@angular/core';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
	selector: 'app-upload-model',
	templateUrl: './upload-model.component.html',
	styleUrls: ['./upload-model.component.scss']
})
export class UploadModelComponent implements OnInit {
	files: File[] = [];
	imageFiles: File[] = [];
	message = 'Drop Your Model Here (.glb)';
	constructor(
		private _notification: NotificationService) { }

	ngOnInit(): void {
	}

	onSelect(event) {
		console.log(event);
		event.rejectedFiles.forEach(element => {
			if (element.reason === 'type') {
				this._notification.danger(element.name + ' is invalid file format. 3D Model have to be .glb format.');
			}
		});

		event.addedFiles.forEach(element => {
			this.files[0] = element;
			this._notification.success(element.name + ' successfully added');
		});
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}

	onSelectImage(event) {
		console.log(event);

		event.rejectedFiles.forEach(element => {
			if (element.reason === 'type') {
				this._notification.danger(element.name + ' is invalid file format. Content image have to be .jpg or .png format.');
			}
			if (element.reason === 'size') {
				this._notification.danger(element.name + ' is too big. Maximum size 1 mb');
			}
		});

		this.imageFiles.push(...event.addedFiles);
		event.addedFiles.forEach(element => {
			this._notification.success(element.name + ' successfully added');
		});
	}

	onRemoveImage(event) {
		console.log(event);
		this.imageFiles.splice(this.imageFiles.indexOf(event), 1);
	}
}
