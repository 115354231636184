import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private toastr: ToastrService) { }
  public from = 'top';
  public align = 'right';

  configInfo = {
    debug: false,
    newestOnTop: true,
    progressBar: false,
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 500,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut', 
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-info alert-with-icon',
    positionClass: 'toast-' + this.from + '-' + this.align
  }
  configSuccess = {
    debug: false,
    newestOnTop: true,
    progressBar: false,
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 500,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut', 
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-success alert-with-icon',
    positionClass: 'toast-' + this.from + '-' + this.align
  }
  configWarning = {
    debug: false,
    newestOnTop: true,
    progressBar: false,
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 500,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut', 
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-warning alert-with-icon',
    positionClass: 'toast-' + this.from + '-' + this.align
  }
  configDanger = {
    debug: false,
    newestOnTop: true,
    progressBar: false,
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 500,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut', 
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-danger alert-with-icon',
    positionClass: 'toast-' + this.from + '-' + this.align
  }
  configDefault = {
    debug: false,
    newestOnTop: true,
    progressBar: false,
    preventDuplicates: false,
    showDuration: 300,
    hideDuration: 500,
    timeOut: 5000,
    extendedTimeOut: 1000,
    showEasing: 'swing',
    hideEasing: 'linear',
    showMethod: 'fadeIn',
    hideMethod: 'fadeOut', 
    closeButton: true,
    enableHtml: true,
    toastClass: 'alert alert-primary alert-with-icon',
    positionClass: 'toast-' + this.from + '-' + this.align
  }

  info(_message, _title = '') {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ' + _message
      , _title
      , this.configInfo);
  }
  success(_message, _title = '') {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ' + _message
      , _title
      , this.configSuccess);
  }
  warning(_message, _title = '') {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ' + _message
      , _title
      , this.configWarning);
  }
  danger(_message, _title = '') {
    this.toastr.info('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ' + _message
      , _title
      , this.configDanger);
  }
  show(_message, _title = '') {
    this.toastr.show('<span class="tim-icons icon-bell-55" [data-notify]="icon"></span> ' + _message
      , _title
      , this.configDefault);
  }
}
