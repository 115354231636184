import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { User } from 'firebase';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from './notification.service';
import { UserService } from './user.service';
import { Client } from '../models/client.model';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	user: User;

	constructor(public afAuth: AngularFireAuth,
		public router: Router,
		private _notification: NotificationService,
		private userService: UserService) {
		this.afAuth.authState.subscribe(user => {
			if (user) {
				this.user = user;
				localStorage.setItem('user', JSON.stringify(this.user));
			} else {
				localStorage.setItem('user', null);
			}
		})
	}

	async login(email: string, password: string) {
		await this.afAuth.signInWithEmailAndPassword(email, password)
			.then(res => {
				console.log(res);
				localStorage.setItem('user', JSON.stringify(res.user));
				console.log(JSON.parse(localStorage.getItem('user')));

				if (res.user.emailVerified) {
					this.userService.update(email, { emailVerified: true });
					this._notification.success('Wellcome to Art|then');
					this.router.navigate(['dashboard']);
				} else {
					this.sendEmailVerification();
				}
			})
			.catch(err => {
				this._notification.danger(err.message);
			});
	}

	async register(email: string, password: string, name: string, surname: string) {
		await this.afAuth.createUserWithEmailAndPassword(email, password)
			.catch(err => this._notification.danger(err.message))
			.then(result => {
				console.log(result);
				if (result) {
					const client = new Client();
					client.email = email;
					client.name = name;
					client.surname = surname;
					client.emailVerified = result.user.emailVerified;
					this.userService.create(client)
						.catch(err => this._notification.warning(err.message))
						.then(res => {
							console.log(res);
							this.sendEmailVerification();
							this.router.navigate(['login']);
						});
				}
			});

	}

	async sendEmailVerification() {
		this.router.navigate(['/verify-email']);
		await (await this.afAuth.currentUser).sendEmailVerification()
			.catch(err => this._notification.danger(err.message))
			.then(err => this._notification.success('Check inbox for email verification'));
	}

	async sendPasswordResetEmail(passwordResetEmail: string) {
		await this.afAuth.sendPasswordResetEmail(passwordResetEmail);
	}

	async applyActionCode(actionCode) {
		console.log(actionCode);
		return await this.afAuth.applyActionCode(actionCode)
	}

	async logout() {
		await this.afAuth.signOut();
		localStorage.removeItem('user');
		this.router.navigate(['login']);
	}

	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem('user'));
		return user !== null;
	}

	get isEmailVerified(): boolean {
		const user: User = JSON.parse(localStorage.getItem('user'));
		if (user != null && user.emailVerified != null && user.emailVerified) {
			return true;
		} else {
			return false;
		}
	}

	async loginWithGoogle() {
		await this.afAuth.signInWithPopup(new auth.GoogleAuthProvider())
		this.router.navigate(['admin/list']);
	}
}
